import Vue from "vue";
import VueRouter from "vue-router";
import About from "../view/About";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "about",
    component: About
  },
  {
    path: "/academy",
    name: "academy",
    component: () => import("../view/Academy")
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../view/Faq")
  },
  {
    path: "/contactus",
    name: "contact us",
    component: () => import("../view/ContactUs")
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../view/Gallery")
  },
  {
    path: "/camp",
    name: "camp",
    component: () => import("../view/Camp")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
