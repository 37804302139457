<template>
  <section
    class="sec--left"
    :style="{ 'background-color': sectionData.bgColor }"
  >
    <div class="wrap--left">
      <swiper :options="swiperOption" class="wrap--img">
        <!-- slides -->
        <swiper-slide
          v-for="(slide, index) in sectionData.imgUrl"
          :key="index"
          :style="{ backgroundImage: 'url(' + slide + ')' }"
        ></swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-button-prev" slot="button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
            <path
              d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z"
            ></path>
          </svg>
        </div>
        <div class="swiper-button-next" slot="button-next">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
            <path
              d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z"
            ></path>
          </svg>
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="wrap--contents">
        <div class="wrap--title">
          <div class="top--title">{{ sectionData.title }}</div>
        </div>
        <div class="mid--desc" v-html="sectionData.moreDesc"></div>
        <div
          class="btn--more"
          v-if="sectionData.btnText != ''"
          style="z-index: 10;"
          @click="
            isShowItem = sectionData.btnText == 'Price List' ? true : false
          "
        >
          {{ sectionData.btnText }}
        </div>
      </div>
    </div>
    <div class="cont--detail animated fadeIn" v-show="isShowItem">
      <div class="wrap--cont">
        <div class="cont--textbox">
          <div class="btn--close" @click="isShowItem = false">X</div>
          <div class="wrap--textbox">
            <div class="title">Price for club repairs</div>
            <table>
              <tr>
                <td>New grip installation</td>
                <td>$2.00 + new grip</td>
              </tr>
              <tr>
                <td>Re-attach Head for Steel</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Re-attach Head for Graphite</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Shorten Club</td>
                <td>$10.00 + new grip</td>
              </tr>
              <tr>
                <td>Shaft Extension</td>
                <td>$15.00 + new grip</td>
              </tr>
              <tr>
                <td>Re-shaft for Steel</td>
                <td>$20.00 + new shaft</td>
              </tr>
              <tr>
                <td>Re-shaft for Graphite</td>
                <td>$25.00 + new shaft</td>
              </tr>
              <tr>
                <td>Lie/Loft Adjustment</td>
                <td>$5.00 per club/ 40 for full bag)</td>
              </tr>
              <tr>
                <td>Swing Weight Adjustment</td>
                <td>$10.00 + new grip</td>
              </tr>
            </table>
          </div>
          <div class="wrap--textbox">
            <div class="title">Custom Fitting</div>
            <table>
              <tr>
                <td>Wood Fitting</td>
                <td>$49.99(allow 30-45 min)</td>
              </tr>
              <tr>
                <td>Iron Fitting</td>
                <td>$99.99(allow 45-60 min)</td>
              </tr>
              <tr>
                <td>Putter Fitting</td>
                <td>$99.99(allow 45-60 min)</td>
              </tr>
              <tr>
                <td>Full Club Fitting</td>
                <td>$199.99(allow 60-90 min)</td>
              </tr>
            </table>
          </div>
          <div class="wrap--textbox">
            <div class="note">
              Note: Trackman Launch Monitor and Quintic Ball Roll System will be
              used for all Club Fitting sessions at Golf Mecca Center. All
              prices are subject to GST/PST taxes.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "SectionLeft",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      isShowItem: false,
      swiperOption: {
        watchOverflow: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          delay: 2000
        }
      }
    };
  },
  methods: {},
  props: ["sectionData"]
};
</script>
