import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faAward,
  faClock,
  faCalendarDay,
  faThumbsUp,
  faUserEdit,
  faPlayCircle
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faAngleDown,
  faAngleUp,
  faAward,
  faClock,
  faCalendarDay,
  faThumbsUp,
  faUserEdit,
  faPlayCircle
);

import {
  faFacebookF,
  faInstagram,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
library.add(faFacebookF, faInstagram, faYoutube);

import "animate.css/animate.css";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
