<template>
  <section class="sec--headline">
    <div class="wrap--headline">
      <div class="head--title" v-html="headTitle"></div>
      <div class="head--sub-title" v-html="headSubTitle"></div>
      <div class="head--desc" v-html="headDesc"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopHeadLine",
  data: () => {
    return {
      headTitle: `THE BEST GOLF FACILITY`,
      headSubTitle: `The 422-acres golf facility is the finest for learning and improving your game`,
      headDesc: `• Two championship courses • Four golf courses within 15 km • All-weather 350 yards driving range<br>
• All-weather 12 indoor artificial mats • Two grass practice stations • Two indoor private hitting bays<br>
• 40 yard x 20 yard practice green • 30 yard x15 yard putting green<br>
• Two large practice bunkers • A luxurious, 65,000 sqft clubhouse • Gym / Game room`
    };
  }
};
</script>
