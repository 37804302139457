<template>
  <div id="app">
    <Header
      id="gnb--desktop"
      :menus="footerMenus"
      :about-txt="aboutTxtDatas"
      :academy-txt="academyTxtDatas"
      @move0="scrollAnchor0"
      @move1="scrollAnchor1"
    ></Header>
    <MHeader id="gnb--mobile" :menus="footerMenus"></MHeader>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import $ from "jquery";

import Header from "./components/common/Header";
import MHeader from "./components/common/MHeader";
import Footer from "./components/common/Footer";

export default {
  name: "app",
  components: {
    Header,
    MHeader,
    Footer
  },
  data: () => {
    return {
      activeNumber: null,
      footerMenus: [
        { id: "0", link: "/", title: "ABOUT" },
        { id: "1", link: "/academy", title: "ACADEMY" },
        { id: "2", link: "/gallery", title: "GALLERY" },
        { id: "3", link: "", title: "ONLINE SHOP" },
        { id: "4", link: "/faq", title: "FAQ" },
        { id: "5", link: "/contactus", title: "CONTACT US" }
      ],
      aboutTxtDatas: [
        { id: "0", link: "", title: "GOLF COURSES" },
        { id: "1", link: "", title: "Practice Facilities" },
        { id: "2", link: "", title: "Teaching Equipments" },
        { id: "3", link: "", title: "PRO SHOP" },
        { id: "4", link: "", title: "Custom Fitting" },
        { id: "5", link: "", title: "Partner" }
      ],
      academyTxtDatas: [
        { id: "0", link: "", title: "Gerenal Lesson" },
        { id: "1", link: "", title: "LTPD" },
        { id: "2", link: "", title: "GM College" },
        { id: "3", link: "", title: "International" },
        { id: "4", link: "", title: "Instructors" },
        { id: "5", link: "", title: "Alumni & Awards" }
      ]
    };
  },
  methods: {
    scrollAnchor0: id => {
      let idName;
      if (id == 0) {
        idName = "#sec2";
      }
      if (id == 1) {
        idName = "#sec3";
      }
      if (id == 2) {
        idName = "#sec4";
      }
      if (id == 3) {
        idName = "#sec5";
      }
      if (id == 4) {
        idName = "#sec6";
      }
      if (id == 5) {
        idName = "#sec7";
      }
      let top = $(idName).offset().top;
      $("html, body").animate(
        {
          scrollTop: top - 40
        },
        1000
      );
    },
    scrollAnchor1: id => {
      let idName;
      if (id == 0) {
        idName = "#sec0";
      }
      if (id == 1) {
        idName = "#sec1";
      }
      if (id == 2) {
        idName = "#sec2";
      }
      if (id == 3) {
        idName = "#sec3";
      }
      if (id == 4) {
        idName = "#sec4";
      }
      if (id == 5) {
        idName = "#sec5";
      }
      let top = $(idName).offset().top;
      $("html, body").animate(
        {
          scrollTop: top - 40
        },
        1000
      );
    }
  },
  mounted() {} //Close Mounted
};
</script>

<style lang="scss">
@import "~normalize-scss/sass/normalize";
@import "./scss/main";
#app {
  overflow-x: hidden;
}
</style>
