<template>
  <nav
    class="cont--mobile"
    :class="{ 'fixed--position': fixedFlag, 'normal--position': !fixedFlag }"
  >
    <div class="wrap--mobile">
      <div
        class="top--logo"
        :class="{
          'top--logo-off': logoRemoveFlag,
          'top--logo-on': !logoRemoveFlag
        }"
      >
        <router-link to="/"
          ><img src="../../assets/about/logo_white.png" alt=""
        /></router-link>
      </div>
      <div
        class="nav-icon4"
        :class="{ 'icon--open': openFlag }"
        @click="toggleClick()"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="m--nav-btn-bg" :class="{'wrap--m-menu-btn-bg-on':menusFlag,'wrap--m-menu-btn-bg-off':!menusFlag}"></div>
    </div>
    <div class="wrap--menus animated fadeInRight" v-if="openFlag">
      <div class="left--menu" @click="openFlag = !openFlag"></div>
      <div class="right--menu">
        <ul>
          <router-link
            :to="item.link"
            v-for="(item, index) in menus"
            :key="index"
            ><li
              :class="{ active: $route.name == item.title.toLowerCase() }"
              @click="openFlag = !openFlag"
            >
              {{ item.title }}
            </li></router-link
          >
          <li>
            <router-link to="/"
              ><img
                @click="openFlag = !openFlag"
                src="../../assets/about/logo-black.png"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
//import $ from "jquery";
export default {
  name: "MHeader",
  props: ["menus"],
  data: () => {
    return {
      openFlag: false,
      fixedFlag: false,
      logoRemoveFlag: false,
      menusFlag: false
    };
  },
  methods: {
    toggleClick: function() {
      let vm = this;
      vm.openFlag = !vm.openFlag;
      if (vm.openFlag) {
        vm.menusFlag = false;
      } else {
        vm.menusFlag = true;
      }
    },
    scrollListener: function() {
      let vm = this;
      if (window.scrollY > 55) {
        vm.fixedFlag = true;
        vm.logoRemoveFlag = true;
        if (vm.openFlag) {
          vm.menusFlag = false;
        } else {
          vm.menusFlag = true;
        }
      } else {
        vm.fixedFlag = false;
        vm.logoRemoveFlag = false;
        vm.menusFlag = false;
      }
    }
  },
  mounted: function() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", this.scrollListener);
  },
  created() {}
};
</script>
