<template>
  <nav class="gm--gnb gnb--top">
    <div class="gnb--menus">
      <div class="wrap--gnb">
        <router-link to="/">
          <div class="menus--logo">
            <img src="../../assets/about/logo_white.png" alt="" />
          </div>
        </router-link>
        <ul class="menus--mid">
          <router-link to="/"
            ><li>
              {{ menus[0].title }}
              <font-awesome-icon
                icon="angle-down"
                class="font-ad"
              ></font-awesome-icon></li
          ></router-link>
          <router-link to="/academy"
            ><li>
              {{ menus[1].title }}
              <font-awesome-icon
                icon="angle-down"
                class="font-ad"
              ></font-awesome-icon>
            </li>
          </router-link>
          <router-link to="/gallery">
            <li>
              {{ menus[2].title }}
            </li>
          </router-link>
        </ul>
        <ul class="menus--right">
          <li>{{ menus[3].title }}</li>
          <router-link to="/faq"
            ><li>{{ menus[4].title }}</li></router-link
          >
          <router-link to="/contactus"
            ><li>{{ menus[5].title }}</li></router-link
          >
        </ul>
      </div>
    </div>
    <div class="snb--menus0" v-if="$route.name == 'about'">
      <ul>
        <li>About</li>
        <li>/</li>
        <li
          v-for="(item, index) in aboutTxt"
          :key="item.id"
          :id="'about' + index"
          @click="$emit('move0', index)"
        >
          {{ item.title }}
          <div class="line"></div>
        </li>
      </ul>
    </div>
    <div class="snb--menus1" v-if="$route.name == 'academy'">
      <ul>
        <li>Academy</li>
        <li>/</li>
        <li
          v-for="(item, index) in academyTxt"
          :key="item.id"
          :id="'academy' + index"
          @click="$emit('move1', index)"
        >
          {{ item.title }}
          <div class="line"></div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "Header",
  props: ["menus", "aboutTxt", "academyTxt", "isActiveNumber"],
  components: {
    FontAwesomeIcon
  },
  data: () => {
    return {};
  },
  methods: {
    setNavAbout: () => {
      $(".gm--gnb").addClass("gnb--fix");
      $(".gm--gnb").removeClass("gnb--top");
      let lastScrollTop = 0;
      $(window).scroll(function() {
        let wid = $(this).innerWidth();
        let st = $(this).scrollTop();
        if (wid > 990) {
          if (st > lastScrollTop) {
            $(".gm--gnb").removeClass("gnb--fix");
            $(".gm--gnb").addClass("gnb--top");

            if (st > 700) {
              $(".snb--menus0").css({ display: "flex" });
              $(".snb--menus1").css({ display: "flex" });
            }
          } else {
            $(".gm--gnb").addClass("gnb--fix");
            $(".gm--gnb").removeClass("gnb--top");
            $(".gm--gnb .wrap--gnb").addClass("wrap--gnb-in");
            if (st < 700) {
              $(".gm--gnb .wrap--gnb").removeClass("wrap--gnb-in");
              $(".snb--menus0").css({ display: "none" });
              $(".snb--menus1").css({ display: "none" });
            }
          }
        }
        lastScrollTop = st;
      });
    }
  },
  mounted() {
    this.setNavAbout();
    $(".snb--menus0").css({ display: "none" });
    $(".snb--menus1").css({ display: "none" });
  }
};
</script>
