<template>
  <footer class="gm--footer">
    <div class="cont--footer">
      <div class="wrap--left">
        <router-link to="/"
          ><img src="../../assets/about/logo-head.png" alt="" class="logo"
        /></router-link>
        <div class="address">
          Golf Mecca Center.<br />
          16651 Rannie Road Pitt Meadows, BC, V3Y 1Z1 CANADA
        </div>
        <div class="email">
          <a href="mailto:info@thegolfmecca.com">info@thegolfmecca.com</a>
        </div>
        <div class="copyright">
          Copyright @2021 Golf Mecca Center. All rights reserved
        </div>
      </div>
      <div class="wrap--right">
        <div class="title">FOLLOW US :</div>
        <ul class="social--links">
          <li class="links" @click="goSocialLink(1)">
            <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
          </li>
        </ul>
        <ul class="service--links">
          <li class="service">Terms & Conditions</li>
          <li class="service">|</li>
          <li class="service">Privacy Policy</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => {
    return {};
  },
  methods: {
    goSocialLink: id => {
      let index = id;
      let url;
      if (index == 0) {
        url = "https://www.facebook.com/GolfMecca-465349250591026/";
      } else if (index == 1) {
        url = "https://www.instagram.com/golf_mecca_center/";
      } else if (index == 2) {
        url = "https://www.youtube.com/channel/UCJjma2vQtKVEx2xw7b4RqzQ";
      }
      window.open(url, "_blank");
    }
  },
  mounted: () => {}
};
</script>
