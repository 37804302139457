<template>
  <section class="sec--partner">
    <div class="cont--top-pt">
      <div class="top--title" v-html="sectionTxt.title"></div>
    </div>
    <ul class="cont--partner">
      <li
        class="box--pt"
        v-for="(item, index) in brandsDatas"
        :key="index"
        :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }"
      >
        <div class="wrap--txt">
          <div class="txt--name">{{ item.name }}</div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import Brand01 from "@/assets/brands/brand01.png";
import Brand02 from "@/assets/brands/brand07.png";
import Brand03 from "@/assets/brands/brand12.png";
import Brand04 from "@/assets/brands/brand02.png";
import Brand05 from "@/assets/brands/brand08.png";
import Brand06 from "@/assets/brands/brand14.png";
import Brand07 from "@/assets/brands/brand03.png";
import Brand08 from "@/assets/brands/brand09.jpg";
import Brand09 from "@/assets/brands/brand15.jpg";
import Brand10 from "@/assets/brands/brand04.jpg";
import Brand11 from "@/assets/brands/brand10.jpg";
import Brand12 from "@/assets/brands/brand16.jpg";
import Brand13 from "@/assets/brands/brand05.jpg";
import Brand14 from "@/assets/brands/brand11.jpg";
import Brand15 from "@/assets/brands/brand17.jpg";
import Brand16 from "@/assets/brands/brand06.jpg";

export default {
  name: "Partner",
  data: () => {
    return {
      sectionTxt: {
        title: `Partner`
      },
      brandsDatas: [
        {
          name: "West Coast Golf Group",
          imgUrl: Brand01,
          link: "https://wcgg.golf/"
        },
        {
          name: "V1 Sports",
          imgUrl: Brand02,
          link: "https://www.v1sports.com/"
        },
        {
          name: "Quintic",
          imgUrl: Brand03,
          link: "http://www.quinticsports.com/"
        },
        {
          name: "Trackman",
          imgUrl: Brand04,
          link: "https://trackmangolf.com/"
        },
        {
          name: "Nippon Shaft",
          imgUrl: Brand05,
          link: "https://nipponshaft.com/"
        },
        { name: "Miura", imgUrl: Brand06, link: "https://miuragolf.com/" },
        {
          name: "Bettinardi",
          imgUrl: Brand07,
          link: "https://bettinardi.com/"
        },
        {
          name: "Basileus",
          imgUrl: Brand08,
          link: "http://www.basileusgolf.com/"
        },
        { name: "ITOBORI", imgUrl: Brand09, link: "https://www.itobori.com/" },
        {
          name: "Graphite design",
          imgUrl: Brand10,
          link: "https://www.gd-inc.co.jp/"
        },
        { name: "HONMA", imgUrl: Brand11, link: "https://us.honmagolf.com/" },
        {
          name: "Super Stroke",
          imgUrl: Brand12,
          link: "https://superstrokeusa.com/"
        },
        { name: "KBS", imgUrl: Brand13, link: "https://kbsgolfshafts.com/" },
        { name: "Muziik", imgUrl: Brand14, link: "http://muziik-golf.com/" },
        {
          name: "TaylorMade",
          imgUrl: Brand15,
          link: "https://www.taylormadegolf.ca/"
        },
        { name: "Volvik", imgUrl: Brand16, link: "http://www.volvik.com/" }
      ]
    };
  },
  methods: {
    getBannerUrl: url => {
      window.open(url, "_blank");
    }
  }
};
</script>
