<template>
  <section id="about">
    <Hero id="sec0"></Hero>
    <TopHeadLine id="sec1"></TopHeadLine>
    <SectionRight :section-data="sectionData01" id="sec2"></SectionRight>
    <SectionLeft :section-data="sectionData02" id="sec3"></SectionLeft>
    <Hitech id="sec4"></Hitech>
    <SectionRight :section-data="sectionData03" id="sec5"></SectionRight>
    <SectionLeft :section-data="sectionData04" id="sec6"></SectionLeft>
    <Partner id="sec7"></Partner>
  </section>
</template>

<script>
//import $ from "jquery";

import sec01img from "@/assets/about/sec01.jpg";
import sec2Sl01 from "@/assets/about/sec02_sl1.jpg";
import sec2Sl02 from "@/assets/about/sec02_sl2.jpg";
import sec2Sl03 from "@/assets/about/sec02_sl3.jpg";
import sec2Sl04 from "@/assets/about/sec02_sl4.jpg";
import sec2Sl05 from "@/assets/about/sl-0054.jpg";
import sec2Sl06 from "@/assets/about/sl-0056.jpg";
import sec2Sl07 from "@/assets/about/sl-0074.jpg";

import sec4img from "@/assets/about/sec4.jpg";
import sec5img from "@/assets/about/sec5.jpg";

import Hero from "../components/about/Hero";
import TopHeadLine from "../components/about/TopHeadLine";
import SectionLeft from "../components/about/SectionLeft";
import SectionRight from "../components/about/SectionRight";
import Hitech from "../components/about/Hitech";
import Partner from "../components/about/Partner";

export default {
  name: "about",
  components: {
    Hero,
    TopHeadLine,
    SectionLeft,
    SectionRight,
    Hitech,
    Partner
  },
  data: () => {
    return {
      footerMenus: [
        { id: "0", link: "", title: "ABOUT" },
        { id: "1", link: "", title: "ACADEMY" },
        { id: "2", link: "", title: "GALLERY" },
        { id: "3", link: "", title: "ONLINE SHOP" },
        { id: "4", link: "", title: "FAQ" },
        { id: "5", link: "", title: "CONTACT US" }
      ],
      sectionData01: {
        imgUrl: [sec01img],
        bgColor: "#fff",
        title: "Golf Courses",
        subTitle:
          "Swan-E-Set Bay Golf Resort,<br>the Home of Golf Mecca Center",
        desc:
          "With two Championship Golf courses designed by PGA legend Lee Trevino, the world class practice facilities and internationally renowned PGA professionals, Golf Mecca Center offers you the best golf experience to the highest level.",
        moreDesc: `
        <span class="txt-bold">Swan-E-Set Clubhouse</span><br>
        <ul>
            <li>A luxurious, 65,000 sqft Clubhouse</li>
            <li>Jacuzzi, Sauna, Men & Women’s locker room</li>
            <li>Gym, Game room</li>
            <li>Restaurant, Lounge, Snack bar</li>
        </ul>
        <br>
        <span class="txt-bold">Other golf courses within 15 KM</span><br>
        <ul>
            <li>Golden Eagle golf club (North & South courses) / within 2 min </li>
            <li>Meadow Garden golf club / within 10 min</li>
            <li>Pitt Meadows golf club / within 8 min </li>
        </ul>`,
        btnLink: "",
        btnText: ""
      },
      sectionData02: {
        imgUrl: [
          sec2Sl05,
          sec2Sl06,
          sec2Sl07,
          sec2Sl01,
          sec2Sl02,
          sec2Sl03,
          sec2Sl04
        ],
        bgColor: "#f5f5f5",
        title: "Practice Facilities",
        subTitle: "",
        desc: "",
        moreDesc: `<span class="txt-bold">Driving Range</span><br>
              <ul>
                  <li>All-weather 350 yards driving range</li>
                  <li>All-weather 12 indoor artificial mats</li>
                  <li>Two 60 yard x 30 yard grass practice stations</li>
              </ul>
              <br>
              <span class="txt-bold">GM Studio</span><br>
                      <ul>
                          <li>Two indoor private hitting bays (Golf Mecca students only)</li>
                      </ul>
                      <br>
              <span class="txt-bold">Short Game Area</span><br>
                      <ul>
                          <li>40 yard x 30 yard practice green for putting and short game</li>
                          <li>Two large practice bunkers</li>
                      </ul>
                      <br>
              <span class="txt-bold">Putting Green</span><br>
                      <ul>
                          <li>30 yard x 20 yard putting green</li>
                      </ul>
                      <br>
              <span class="txt-bold">Golf Course</span><br>
                      <ul>
                         <li>Two Championship courses (Swan-E-Set Bay Golf Resort)</li>
                      </ul>
`,
        btnLink: "",
        btnText: ""
      },
      sectionData03: {
        imgUrl: [sec4img],
        bgColor: "#f5f5f5",
        title: "Pro Shop",
        subTitle: "",
        desc: "",
        moreDesc: `We specialize in High-End Japanese made golf equipment and have a great range of products to suit every budget.<br>
        Everyone is welcome to try our demo clubs on our practice facility.<br><br>
        We are the authorized fitting center for TaylorMade Golf and dealership of custom Japanese brand like Miura, Epon, Itobori, and more.<br><br>
        Keep your eyes on this page for our latest offers!`,
        btnLink: "",
        btnText: ""
      },
      sectionData04: {
        imgUrl: [sec5img],
        bgColor: "#fff",
        title: "Fitting Shop",
        subTitle: "",
        desc: "",
        moreDesc: `At Golf Mecca Centre, we focus on giving you an elite level fitting experience by making it enjoyable and informative. We are committed to identifying the best set of clubs a player needs to perform their best by utilizing launch monitor technology which allows us to test any club head with any shaft.
<br><br>Our studio boasts the following features<br>
<ul>
<li>Private hitting bay</li>
<li>Trackman Launch Monitor</li>
<li>Quintic Ball Roll System</li>
<li>More than 40 demo clubs</li>
</ul>`,
        btnLink: "",
        btnText: "Price List"
      },
      isAboutAct: true,
      isAcademyAct: false
    };
  },
  methods: {},
  mounted() {
    /*
                // init controller
                const controller = new ScrollMagic.Controller({});

                //Headline
                const headLineTl = new TimelineMax();
                const headTw1 = TweenMax.fromTo(
                  ".head--title",
                  0.3,
                  {
                    top: -128,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: -118,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const headTw2 = TweenMax.fromTo(
                  ".head--desc",
                  0.3,
                  {
                    top: 10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 20,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                headLineTl.add(headTw1).add(headTw2);

                const secLeftTl = new TimelineMax();
                const scLeftTw1 = TweenMax.fromTo(
                  "#sec2-1 > .wrap--left > .wrap--img",
                  0.4,
                  {
                    left: -5,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    left: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scLeftTw3 = TweenMax.fromTo(
                  "#sec2-1 .wrap--left > .wrap--contents > .top--img",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scLeftTw4 = TweenMax.fromTo(
                  "#sec2-1 .wrap--left > .wrap--contents > .mid--desc",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scLeftTw5 = TweenMax.fromTo(
                  "#sec2-1 .wrap--left > .wrap--contents > .btn--more",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                secLeftTl
                  .add([scLeftTw3, scLeftTw4, scLeftTw5], "0", "sequence", 0.1)
                  .add([scLeftTw1]);

                const secRightTl = new TimelineMax();
                const scRightTw1 = TweenMax.fromTo(
                  "#sec2-2 >.wrap--right > .wrap--img",
                  0.4,
                  {
                    right: -5,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    right: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw2 = TweenMax.fromTo(
                  "#sec2-2 .wrap--right .wrap--title .top--line",
                  0.2,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw3 = TweenMax.fromTo(
                  "#sec2-2 .wrap--right .wrap--title .top--title",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw4 = TweenMax.fromTo(
                  "#sec2-2 .wrap--right .mid--desc",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                secRightTl
                  .add([scRightTw2, scRightTw3, scRightTw4], "0", "sequence", 0.1)
                  .add([scRightTw1]);

                const slTl1 = new TimelineMax();
                const slTw1 = TweenMax.fromTo(
                  "#sec2-3 .wrap--left .wrap--img",
                  0.4,
                  {
                    left: -5,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    left: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const slTw2 = TweenMax.fromTo(
                  "#sec2-3 .wrap--left .wrap--contents .top--line",
                  0.2,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const slTw3 = TweenMax.fromTo(
                  "#sec2-3 .wrap--left .wrap--contents .top--title",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const slTw4 = TweenMax.fromTo(
                  "#sec2-3 .wrap--left .wrap--contents .mid--desc",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const slTw5 = TweenMax.fromTo(
                  "#sec2-3 .wrap--left .wrap--contents .btn--more",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                slTl1.add([slTw2, slTw3, slTw4, slTw5], "0", "sequence", 0.1).add([slTw1]);

                const secRightTl1 = new TimelineMax();
                const scRightTw11 = TweenMax.fromTo(
                  "#sec2-4 .wrap--right .wrap--img",
                  0.4,
                  {
                    right: -5,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    right: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw12 = TweenMax.fromTo(
                  "#sec2-4 .wrap--right .top--line",
                  0.2,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw13 = TweenMax.fromTo(
                  "#sec2-4  .wrap--right .top--title",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw14 = TweenMax.fromTo(
                  "#sec2-4 .wrap--right .mid--desc",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const scRightTw15 = TweenMax.fromTo(
                  "#sec2-4 .wrap--right .btn--more",
                  0.2,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );

                secRightTl1
                  .add(
                    [scRightTw12, scRightTw13, scRightTw14, scRightTw15],
                    "0",
                    "sequence",
                    0.1
                  )
                  .add([scRightTw11]);

                const tsTl = new TimelineMax();
                const tsTw1 = TweenMax.fromTo(
                  "#sec3 .top--line",
                  0.3,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const tsTw2 = TweenMax.fromTo(
                  "#sec3 .top--title",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const tsTw3 = TweenMax.fromTo(
                  "#sec3 .top--desc",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const tsTw4 = TweenMax.fromTo(
                  "#sec3 .wrap--bg-ts",
                  0.4,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const tsTw5 = TweenMax.fromTo(
                  "#sec3 .wrap--banners",
                  0.4,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                tsTl.add([tsTw1, tsTw2, tsTw3, tsTw4, tsTw5], "0", "sequence", 0.1);
                const alTl = new TimelineMax();
                const alTw1 = TweenMax.fromTo(
                  "#sec4 .top--line",
                  0.3,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const alTw2 = TweenMax.fromTo(
                  "#sec4 .top--title",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const alTw3 = TweenMax.fromTo(
                  "#sec4 .top--desc",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                alTl.add([alTw1, alTw2, alTw3], "0", "sequence", 0.05);

                const alPicTl = new TimelineMax();
                const alTw4 = TweenMax.fromTo(
                  "#sec4 .top--pic01",
                  0.6,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const alTw5 = TweenMax.fromTo(
                  "#sec4 .top--pic02",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                alPicTl.add([alTw4, alTw5]);

                const alPlayerTl = new TimelineMax();
                const alTw6 = TweenMax.staggerFromTo(
                  "#sec4 .wrap--left > li",
                  0.1,
                  { top: -10, opacity: 0, ease: Linear.easeOut },
                  { top: 0, opacity: 1, ease: Linear.easeIn },
                  0.2
                );

                const alTw7 = TweenMax.staggerFromTo(
                  "#sec4 .wrap--right > li",
                  0.1,
                  { top: -10, opacity: 0, ease: Linear.easeOut },
                  { top: 0, opacity: 1, ease: Linear.easeIn },
                  0.2
                );
                alPlayerTl.add([alTw6, alTw7]);

                const glTl = new TimelineMax();
                const glTw1 = TweenMax.fromTo(
                  "#sec5 .top--line",
                  0.3,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const glTw2 = TweenMax.fromTo(
                  "#sec5 .top--title",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const glTw3 = TweenMax.fromTo(
                  "#sec5 .cont--gallery",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                glTl.add([glTw1, glTw2, glTw3], "0", "sequence", 0.1);

                const ptTl = new TimelineMax();
                const ptTw1 = TweenMax.fromTo(
                  "#sec6 .top--line",
                  0.3,
                  {
                    top: -10,
                    width: 0,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    width: 50,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const ptTw2 = TweenMax.fromTo(
                  "#sec6 .top--title",
                  0.3,
                  {
                    top: -10,
                    opacity: 0,
                    ease: Linear.easeOut
                  },
                  {
                    top: 0,
                    opacity: 1,
                    ease: Linear.easeIn
                  }
                );
                const ptTw3 = TweenMax.staggerFromTo(
                  "#sec6 .cont--partner > li",
                  0.1,
                  { top: -10, opacity: 0, ease: Linear.easeOut },
                  { top: 0, opacity: 1, ease: Linear.easeIn },
                  0.2
                );
                ptTl.add([ptTw1, ptTw2, ptTw3], "0", "sequence", 0.1);

                // build scenes
                new ScrollMagic.Scene({ triggerElement: "#sec0" })
                  .setClassToggle("#high0", "active")
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec1" })
                  .setClassToggle("#high0", "active")
                  .setTween(headLineTl)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec2-1" })
                  .setClassToggle("#high1", "active")
                  .setTween(secLeftTl)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec2-2" })
                  .setClassToggle("#high1", "active")
                  .setTween(secRightTl)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec2-3" })
                  .setClassToggle("#high1", "active")
                  .setTween(slTl1)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec2-4" })
                  .setClassToggle("#high1", "active")
                  .setTween(secRightTl1)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec3" })
                  .setClassToggle("#high2", "active")
                  .setTween(tsTl)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: "#sec4" })
                  .setClassToggle("#high3", "active")
                  .setTween(alTl)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: ".cont--imgs" })
                  .setClassToggle("#high3", "active")
                  .setTween(alPicTl)
                  .addTo(controller);

                new ScrollMagic.Scene({ triggerElement: ".cont--players" })
                  .setClassToggle("#high3", "active")
                  .setTween(alPlayerTl)
                  .addTo(controller);
                new ScrollMagic.Scene({ triggerElement: "#sec5" })
                  .setClassToggle("#high3, #high4", "active")
                  .setTween(glTl)
                  .addTo(controller);
                new ScrollMagic.Scene({ triggerElement: "#sec6" })
                  .setClassToggle("#high5", "active")
                  .setTween(ptTl)
                  .addTo(controller);

                 */
  } //Close Mounted
};
</script>
