<template>
  <section
    class="sec--right"
    :style="{ 'background-color': sectionData.bgColor }"
  >
    <div class="wrap--right">
      <div class="wrap--contents">
        <div class="wrap--title">
          <div class="top--title">{{ sectionData.title }}</div>
          <div class="top--sub-title" v-html="sectionData.subTitle"></div>
          <div class="top--desc">{{ sectionData.desc }}</div>
        </div>
        <div class="mid--desc" v-html="sectionData.moreDesc"></div>
        <div class="wrap--spacer" v-if="sectionData.title == 'Pro Shop'"></div>
        <div
          class="btn--more"
          v-if="sectionData.btnText != ''"
          style="z-index: 10;"
        >
          {{ sectionData.btnText }}
        </div>
      </div>
      <swiper :options="swiperOption" class="wrap--img">
        <!-- slides -->
        <swiper-slide
          v-for="(slide, index) in sectionData.imgUrl"
          :key="index"
          :style="{ backgroundImage: 'url(' + slide + ')' }"
        ></swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "SectionRight",
  components: {
    swiper,
    swiperSlide
  },
  props: ["sectionData", "secId"],
  data() {
    return {
      swiperOption: {
        effect: "slide",
        speed: 1600,
        watchOverflow: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          delay: 5000
        }
      }
    };
  },
  methods: {},
  mounted() {}
};
</script>
