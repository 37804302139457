<template>
  <section class="sec--ts">
    <div class="wrap--top-ts">
      <div class="top--title" v-html="sectionTxt.title"></div>
      <div class="top--desc" v-html="sectionTxt.desc"></div>
    </div>
    <ul class="sub--menus">
      <li
        class="menu"
        :class="{ active: isShowItem == 0 }"
        @click="isShowItem = 0"
      >
        TRACKMAN
      </li>
      <li
        class="menu"
        :class="{ active: isShowItem == 1 }"
        @click="isShowItem = 1"
      >
        V1 PRO HD
      </li>
      <li
        class="menu"
        :class="{ active: isShowItem == 2 }"
        @click="isShowItem = 2"
      >
        QUINTIC PUTTING
      </li>
    </ul>
    <div
      class="cont--detail animated fadeIn"
      v-for="(item, index) in boxDatas"
      :key="index"
      :style="{ 'background-image': 'url(' + item.bg + ')' }"
      v-show="isShowItem == index"
    >
      <div class="wrap--left">
        <div class="top--title" v-html="item.title"></div>
        <div class="top--desc">{{ item.desc }}</div>
        <div class="btn--more" @click="gotoLink(index)">{{ item.btnTxt }}</div>
      </div>
      <div class="wrap--right"></div>
    </div>
  </section>
</template>

<script>
import BoxBg01 from "@/assets/about/sec3_sub01.png";
import BoxBg02 from "@/assets/about/sec3_sub02.png";
import BoxBg03 from "@/assets/about/sec3_sub03.png";

export default {
  name: "Hitech",
  data: () => {
    return {
      isShowItem: 0,
      sectionTxt: {
        title: `Hi-Tech Teaching Equipments`,
        desc: `• 270 degrees mirrors • Trackman Launch Monitor • V1 Swing Analysis • Quintic Ball Roll System<br>
• High-speed cameras • Latest & most innovative golf training aids<br>
• Wellputt indoor putting green`
      },
      boxDatas: [
        {
          id: 0,
          bg: BoxBg01,
          title: `Trackman`,
          desc: `The TrackMan numbers are the brain behind it all.
          All major stakeholders of the game, without exception,
          use and trust our numbers for two main reasons: precision and reliability.
          With TrackMan, you will quickly understand why and how they use our data in their daily work.
          With us, you’ll quickly become an insider of the most revolutionary training tool in the industry.`,
          btnTxt: "VISIT WEBSITE",
          btnlink: ""
        },
        {
          id: 1,
          bg: BoxBg02,
          title: `V1 PRO HD`,
          desc: `The Most Powerful Golf Teaching Software
          V1 Pro HD is the most powerful video analysis and golf teaching software on the market for live video capture, swing analysis,
          and lesson creation and delivery. Increase revenue, improve your productivity and student retention,
          and become a better teacher and communicator with the golf teaching software provided by V1 Pro HD`,
          btnTxt: "VISIT WEBSITE",
          btnlink: ""
        },
        {
          id: 2,
          bg: BoxBg03,
          title: `Quintic Putting`,
          desc: `Quintic Ball Roll utilises a high-speed camera (360 - 1080 frames per second) to track the putter
          and golf ball throughout the impact zone of either a right handed or left handed player.
          The system automatically measures over 45 parameters regarding the putter and ball,
          both graphically and numerically. The data is instantly displayed within the software for immediate analysis,
          review and intervention.`,
          btnTxt: "VISIT WEBSITE",
          btnlink: ""
        }
      ]
    };
  },
  methods: {
    gotoLink: id => {
      let url;
      if (id == 0) {
        url = "https://trackmangolf.com/";
      } else if (id == 1) {
        url = "https://v1sports.com/";
      } else if (id == 2) {
        url = "https://www.quinticsports.com/";
      }
      window.open(url, "_blank");
    }
  }
};
</script>
